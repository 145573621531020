/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "下載Bumble",
      "接受全部",
      "我們使用cookie更好的運營網站，包括cookie分析和cookie廣告。更多訊息，請查看 <a p0>Cookie政策</a>。",
      "管理選項",
      "您同意使用cookies",
      "閱讀更多",
      "返回",
      "電話號碼不正確",
      "(c) {{current_year}} Bumble | 保留所有版權",
      "類別",
      "最新",
      "Bumble Buzz",
      "Buzz 已轉移至新網站。如要取得更多關於約會、友誼和身心健康的建議，請前往：<a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>",
      "Buzz 搬新家了！",
      "Bumble 職涯",
      "不許售賣或分享我的個人信息",
      "關注@{{account_name}}",
      "事業",
      "友誼",
      "愛情",
      "提交您的故事",
      "成功故事",
      "健康",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "工作機會",
      "公司",
      "活動",
      "這種結交有力的新朋友的方式是前所未有的。",
      "關注Bumble",
      "指導原則",
      "使用說明",
      "管理 Cookie",
      "隱私權政策",
      "應用",
      "條款",
      "關於",
      "大使",
      "嗡嗡",
      "商店",
      "下載應用",
      "Android下載",
      "iOS下載",
      "對不起，無法找到該網頁。",
      "登入",
      "加入",
      "一起加入吧！",
      "閱讀全部 →",
      "↓ 顯示更多",
      "糟糕，出錯啦"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "天"
            ],
            "singular": [
                  "天"
            ]
      },
      "hour": {
            "plural": [
                  "小時"
            ],
            "singular": [
                  "小時"
            ]
      },
      "minute": {
            "plural": [
                  "分鐘"
            ],
            "singular": [
                  "分鐘"
            ]
      },
      "month": {
            "plural": [
                  "月"
            ],
            "singular": [
                  "月"
            ]
      },
      "second": {
            "plural": [
                  "秒"
            ],
            "singular": [
                  "秒"
            ]
      },
      "year": {
            "plural": [
                  "年"
            ],
            "singular": [
                  "年"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
