/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Ladda ner Bumble",
      "Acceptera alla",
      "Vi använder cookies för en bättre upplevelse. Detta inkluderar cookies för analys och för annonsering. För mer info, kolla in vår <a p0>Cookie-policy</a>.",
      "Se alternativ",
      "Ok med cookies?",
      "Läs mer →",
      "Gå tillbaka",
      "Felaktigt telefonnummer",
      "© {{current_year}} Bumble Alla rättigheter reserverade",
      "Kategorier",
      "Senaste",
      "Bumble Buzz",
      "The Buzz har flyttat till en ny webbplats! Kolla in den och få råd om dejting, vänskap och välmående med mera: <a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>",
      "The Buzz har flyttat!",
      "Jobba på Bumble",
      "Sälj eller dela inte mina personliga uppgifter",
      "Följ @{{account_name}}",
      "Karriär",
      "Vänskap",
      "Kärlek",
      "Skicka in din historia",
      "Framgångshistorier",
      "Hälsa",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Lediga jobb",
      "Företaget",
      "Events",
      "Det har aldrig funnits ett bättre tillfälle att träffa nya, intressanta personer.",
      "Följ Bumble",
      "Riktlinjer",
      "Hjälpcenter",
      "Hantera cookies",
      "Sekretesspolicy",
      "Appen",
      "Villkor och regler",
      "Om",
      "Ambassadörer",
      "The Buzz",
      "Shop",
      "Ladda ner appen",
      "Ladda ner för Android",
      "Ladda ner för iOS",
      "Sorry, vi hittar tyvärr inte sidan du letar efter.",
      "Logga in",
      "Gå med",
      "Bumbla med oss!",
      "Se alla →",
      "↓ Se fler",
      "Hoppsan, något gick fel..."
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "dagar",
                  "dagars"
            ],
            "singular": [
                  "dag",
                  "dags"
            ]
      },
      "hour": {
            "plural": [
                  "timmar",
                  "timmars"
            ],
            "singular": [
                  "timme",
                  "timmes"
            ]
      },
      "minute": {
            "plural": [
                  "minuter",
                  "minuters"
            ],
            "singular": [
                  "minut",
                  "minuts"
            ]
      },
      "month": {
            "plural": [
                  "månader",
                  "månaders"
            ],
            "singular": [
                  "månad",
                  "månads"
            ]
      },
      "second": {
            "plural": [
                  "sekunder",
                  "sekunders"
            ],
            "singular": [
                  "sekund",
                  "sekunds"
            ]
      },
      "year": {
            "plural": [
                  "år",
                  "års"
            ],
            "singular": [
                  "år",
                  "års"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
