export const getCookieSettings = function () {
  const re = new RegExp("[; ]?cookie_settings=(%.+7D;*)");
  const sMatch = global.document && global.document.cookie.match(re);

  if (sMatch) {
    let cookie = sMatch[1];

    // clean up for some edge cases - when people will have 2 and more cookies with different paths
    if (cookie.includes(";")) {
      cookie = cookie.split(";")[0];
    }
    return JSON.parse(decodeURIComponent(unescape(cookie)));
  }

  return false;
};

export const hasUserAnalyticsConsent = (function () {
  const cookieSettings = getCookieSettings();

  return function (analyticsConsentCallback: () => void) {
    // Don't reload existing scripts with ID's
    if (
      cookieSettings &&
      cookieSettings.analytics &&
      analyticsConsentCallback
    ) {
      analyticsConsentCallback();
    }
  };
})();

export const hasUserFunctionalConsent = (function () {
  const cookieSettings = getCookieSettings();

  return function (functionalConsentCallback: () => void) {
    // Don't reload existing scripts with ID's
    if (
      cookieSettings &&
      cookieSettings.functional &&
      functionalConsentCallback
    ) {
      functionalConsentCallback();
    }
  };
})();

export function acceptAllCookies() {
  const cookies = { functional: true, analytics: true };
  const cookieSettings = encodeURIComponent(JSON.stringify(cookies));
  const expiryDate = new Date();
  expiryDate.setTime(Number(new Date()) + 1000 * 60 * 60 * 24 * 365);
  global.document.cookie = `cookie_settings=${cookieSettings}; expires=${expiryDate.toUTCString()}`;
}
