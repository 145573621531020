import { Router } from "next/router";
import supportedLanguageIds from "lib/lexemes/supported-languages";

const languageListString = supportedLanguageIds.join("|");
// Regex which matches combinations of /[language-code]/the-buzz
const BUMBLE_BUZZ_PATH_REGEX = new RegExp(
  `^(/(${languageListString})/)?(the-buzz)?`,
);

/**
 * fix bug with double slash for language after the-buzz
 */
function clearDoubleSlashforUrls(url: string) {
  return url.replace("the-buzz//", "the-buzz/");
}

/**
 * Returns "alternate" languages for SEO
 */
export function getLanguageAlternates(router: Router) {
  const asPath = router ? router.asPath : "";
  const pathName = router ? router.pathname : "";

  // We are in the main page (/the-buzz) or the localised version (/de/the-buzz)
  if (pathName === "/" || pathName === "/[lang]/index-lang") {
    return supportedLanguageIds.map((lang) => {
      return {
        hrefLang: lang,
        href: `https://bumble.com/${lang}/the-buzz`,
      };
    });
  }

  // Filter the final path removing languages and `the-buzz`
  const filteredPath = asPath.replace(BUMBLE_BUZZ_PATH_REGEX, "");

  return supportedLanguageIds.map((lang) => {
    return {
      hrefLang: lang,
      href: clearDoubleSlashforUrls(
        `https://bumble.com/${lang}/the-buzz/${filteredPath}`,
      ),
    };
  });
}

export function getCanonical(router: Router) {
  const asPath = router ? router.asPath : "";
  const pathName = router ? router.pathname : "";

  // We are in the main page (/the-buzz) or the localised version (/de/the-buzz)
  if (pathName === "/" || pathName === "/[lang]/index-lang") {
    return `https://bumble.com/the-buzz`;
  }

  // we are on post page
  let path = asPath.replace(BUMBLE_BUZZ_PATH_REGEX, "");

  // Remove query params
  path = path.replace(/\?.+/, "");

  return clearDoubleSlashforUrls(`https://bumble.com/the-buzz/${path}`);
}
