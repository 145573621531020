/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Baixar o Bumble",
      "Aceitar todos",
      "Usamos os cookies para melhorar o funcionamento do nosso site. Isso inclui cookies analíticos e cookies de publicidade. Para mais informações, confira nossa <a p0>Política de Cookies</a>",
      "Gerenciar opções",
      "Confirme que você concorda com os cookies",
      "Ler mais",
      "Voltar",
      "Número de telefone incorreto",
      "© {{current_year}} Bumble | Todos os direitos reservados",
      "Categorias",
      "Mais recentes",
      "Bumble Buzz",
      "O Blog agora está de cara nova. Lá você irá encontrar conselhos sobre encontros, amizade, bem-estar e muito mais: <a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>",
      "O Buzz está de cara nova!",
      "Empregos no Bumble",
      "Não vender nem compartilhar as minhas informações pessoais",
      "Siga o @{{account_name}}",
      "Carreira",
      "Amizade",
      "Amor",
      "Envie um depoimento",
      "Histórias de Sucesso",
      "Bem-estar",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Vagas",
      "Empresa",
      "Eventos",
      "Nunca houve um melhor momento para conhecer pessoas novas e inspiradoras.",
      "Siga o Bumble",
      "Regras",
      "Ajuda",
      "Gerenciar Cookies",
      "Política de Privacidade",
      "App",
      "Termos e Condições",
      "Sobre",
      "Embaixadores",
      "O Buzz",
      "Loja",
      "Baixar o app",
      "Baixar para Android",
      "Baixar para iOS",
      "Página não encontrada.",
      "Entrar",
      "Registrar",
      "Junte-se a nós!",
      "Ver tudo →",
      "↓ Ver mais",
      "Ops, algo deu errado!"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "dias"
            ],
            "singular": [
                  "dia"
            ]
      },
      "hour": {
            "plural": [
                  "horas"
            ],
            "singular": [
                  "hora"
            ]
      },
      "minute": {
            "plural": [
                  "minutos"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "meses"
            ],
            "singular": [
                  "mês"
            ]
      },
      "second": {
            "plural": [
                  "segundos"
            ],
            "singular": [
                  "segundo"
            ]
      },
      "year": {
            "plural": [
                  "anos"
            ],
            "singular": [
                  "ano"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
