/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Descargar Bumble",
      "Aceptarlas todas",
      "Utilizamos cookies para que nuestro sitio web funcione mejor. Estas incluyen cookies publicitarias y de análisis. Para más información, lee nuestra <a p0>Política de cookies</a>.",
      "Gestionar opciones",
      "Indícanos si aceptas las cookies",
      "Leer más",
      "Volver",
      "Número de teléfono incorrecto",
      "@{{current_year}}Bumble  | Todos los derechos reservados",
      "Categorías",
      "Más recientes",
      "Bumble Buzz",
      "El Blog se ha trasladado a un nuevo sitio web. Aquí encontrarás consejos sobre citas, amistad, bienestar y mucho más: <a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>",
      "¡El Blog tiene un nuevo hogar!",
      "Carreras en Bumble",
      "No vender ni compartir mis datos personales",
      "Seguir @{{account_name}}",
      "Profesional",
      "Amistad",
      "Amor",
      "Cuéntanos tu historia",
      "Historias de éxito",
      "Bienestar",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Empleo",
      "Quiénes somos",
      "Eventos",
      "Nunca habrá mejor momento para conocer gente que nos ayude a ser mejores.",
      "Seguir Bumble",
      "Normas",
      "Centro de ayuda",
      "Gestionar cookies",
      "Política de privacidad",
      "La app",
      "Condiciones de uso",
      "Sobre",
      "Embajadores",
      "Blog",
      "Tienda",
      "Descargar la aplicación",
      "Descargar para Android",
      "Descargar para iOS",
      "Lo sentimos, pero no hemos podido encontrar la página que estabas buscando.",
      "Entrar",
      "Únete",
      "¡Únete!",
      "Ver todo →",
      "↓ Ver más",
      "Vaya, se ha producido un error"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "días"
            ],
            "singular": [
                  "día"
            ]
      },
      "hour": {
            "plural": [
                  "horas"
            ],
            "singular": [
                  "hora"
            ]
      },
      "minute": {
            "plural": [
                  "minutos"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "meses"
            ],
            "singular": [
                  "mes"
            ]
      },
      "second": {
            "plural": [
                  "segundos"
            ],
            "singular": [
                  "segundo"
            ]
      },
      "year": {
            "plural": [
                  "años"
            ],
            "singular": [
                  "año"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
