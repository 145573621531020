import loadScript from "lib/load-script";
import {
  acceptAllCookies,
  hasUserAnalyticsConsent,
} from "lib/has-cookie-consent";

type VendorConsents = {
  consentedVendors: Array<{ _id: string }>;
};

const FB_PIXEL_ID = "5fac56cd1ba05165880458ad";
const PINTEREST_ID = "5e839a38b8e05c4e491e738e";
const PARSELY_ID = "5efb47c3a86ed40d775a6693";

const hasConsent = (vendorConsents: VendorConsents, ID: string) =>
  vendorConsents.consentedVendors.some(
    (vendor: { _id: string }) => vendor._id === ID,
  );

const getVendorsData = function () {
  __tcfapi(
    "getCustomVendorConsents",
    2,
    (vendorConsents: VendorConsents, successForConsent: boolean) => {
      if (successForConsent) {
        const hasFbPixelAnalyticsConsent = hasConsent(
          vendorConsents,
          FB_PIXEL_ID,
        );
        const hasPinterestlAnalyticsConsent = hasConsent(
          vendorConsents,
          PINTEREST_ID,
        );
        const hasParselylAnalyticsConsent = hasConsent(
          vendorConsents,
          PARSELY_ID,
        );

        if (hasFbPixelAnalyticsConsent) {
          setTimeout(() => {
            loadScript("/the-buzz/fb-analytics.js", "fb-analytics");
          }, 100);
        }

        if (hasPinterestlAnalyticsConsent) {
          setTimeout(() => {
            loadScript(
              "/the-buzz/pinterest-analytics.js",
              "pinterest-analytics",
            );
          }, 100);
        }

        if (hasParselylAnalyticsConsent) {
          setTimeout(() => {
            loadScript("/the-buzz/parsely-analytics.js", "parsely-analytics");
          }, 100);
        }
      }
    },
  );
};

export function handleCookieConsent(
  tcData: { gdprApplies: boolean; eventStatus: string; listenerId: string },
  success: boolean,
) {
  if (success) {
    if (
      !tcData.gdprApplies &&
      (tcData.eventStatus === "tcloaded" || tcData.eventStatus === "cmpuishown")
    ) {
      // non-gdpr countries
      // set all cookies allowed
      // @TODO add CCPA + Brazil
      setTimeout(() => {
        hasUserAnalyticsConsent(() => {
          loadScript("/the-buzz/fb-analytics.js", "fb-analytics");
          loadScript("/the-buzz/pinterest-analytics.js", "pinterest-analytics");
          loadScript("/the-buzz/parsely-analytics.js", "parsely-analytics");
          acceptAllCookies();
        });
      }, 400);
    } else if (tcData.gdprApplies) {
      const cookieContainer: HTMLElement | null = document.querySelector(
        ".cookie-notification",
      );

      if (cookieContainer) {
        cookieContainer.style.display = "none";
      }
      // we call script on each page load, if script loaded - it won't load again
      getVendorsData();
      acceptAllCookies();
    }
  }

  return tcData.listenerId;
}

export const onUseCookieConsentEvent = () => {
  const listenerId = __tcfapi?.("addEventListener", 2, handleCookieConsent);

  return () => {
    __tcfapi?.("removeEentListener", 2, null, listenerId);
  };
};

function displayGDPR(event: Event) {
  event.preventDefault();

  try {
    //@ts-ignore
    window._sp_.gdpr.loadPrivacyManagerModal();
  } catch (error) {
    window.location.href = "https://bumble.com/cookie-policy";
  }
}

function displayCCPA(event: Event) {
  event.preventDefault();

  try {
    //@ts-ignore
    window._sp_.ccpa.loadPrivacyManagerModal("708248");
  } catch (error) {
    window.location.href = "https://bumble.com/cookie-policy";
  }
}

export const onUsePrivacyManagerHandler = () => {
  const privacyManagerGDPR = document.querySelector(".js-manage-cookies");
  const privacyManagerCCPA = document.querySelector(".js-ccpa-privacy");

  if (privacyManagerGDPR) {
    privacyManagerGDPR.addEventListener("click", displayGDPR);
  }

  if (privacyManagerCCPA) {
    privacyManagerCCPA.addEventListener("click", displayCCPA);
  }

  return () => {
    if (privacyManagerGDPR) {
      privacyManagerGDPR.removeEventListener("click", displayGDPR);
    }

    if (privacyManagerCCPA) {
      privacyManagerCCPA.removeEventListener("click", displayCCPA);
    }
  };
};
