/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "下载Bumble",
      "全部接受",
      "我们使用cookie来让我们的网站更好地运营。这包括分析cookie和广告cookie。欲了解更多信息，请查阅我们的<a p0>Cookie政策</a>。",
      "管理选项",
      "您同意使用cookie",
      "阅读更多",
      "返回",
      "手机号码不正确",
      "© {{current_year}} Bumble | 保留所有权利",
      "类别",
      "最新",
      "Bumble Buzz",
      "Buzz现已移至新的网址。请在此查看约会、交友、爱生活等丰富内容。<a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>",
      "Buzz搬家啦！",
      "加入Bumble",
      "不允许售卖或​分享​我的​个人​信息",
      "关注@{{account_name}}",
      "事业",
      "友谊",
      "爱情",
      "提交您的故事",
      "成功故事",
      "健康",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "工作机会",
      "公司",
      "活动",
      "这种结交有力的新朋友的方式是前所未有的。",
      "关注Bumble",
      "指导原则",
      "帮助中心",
      "管理Cookies",
      "隐私声明",
      "应用",
      "条款",
      "关于",
      "大使",
      "嗡嗡",
      "商店",
      "下载应用",
      "Android下载",
      "iOS下载",
      "对不起，无法找到该网页。",
      "登录",
      "加入",
      "一起加入吧！",
      "阅读全部 →",
      "↓ 显示更多",
      "啊，发生了一个错误"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "天"
            ],
            "singular": [
                  "天"
            ]
      },
      "hour": {
            "plural": [
                  "小时"
            ],
            "singular": [
                  "小时"
            ]
      },
      "minute": {
            "plural": [
                  "分钟"
            ],
            "singular": [
                  "分钟"
            ]
      },
      "month": {
            "plural": [
                  "月"
            ],
            "singular": [
                  "月"
            ]
      },
      "second": {
            "plural": [
                  "秒"
            ],
            "singular": [
                  "秒"
            ]
      },
      "year": {
            "plural": [
                  "年"
            ],
            "singular": [
                  "年"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
