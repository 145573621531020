/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Descargar Bumble",
      "Aceptar todas",
      "Usamos cookies para que nuestro sitio funcione mejor. Esto incluye cookies de análisis de datos y cookies de anuncios. Para más información, revisá nuestra <a p0>Política de Cookies</a>.",
      "Administrar opciones",
      "Indicanos si aceptás las cookies",
      "Leer más",
      "Atrás",
      "Número de celular incorrecto",
      "@{{current_year}}Bumble | Todos los derechos reservados",
      "Categorías",
      "Más recientes",
      "Bumble Buzz",
      "Renovamos el blog. Consultá todos los consejos sobre citas, amistades, bienestar y mucho más en <a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>",
      "¡El blog está de estreno!",
      "Trabajá en Bumble",
      "No vender o compartir mi información personal",
      "Seguir @{{account_name}}",
      "Trayectoria profesional",
      "Amistad",
      "Amor",
      "Enviar história",
      "Historias de éxito",
      "Bienestar",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Empleo",
      "Quiénes somos",
      "Eventos",
      "Siempre es un buen momento para conocer gente que nos ayude a ser mejores.",
      "Seguir a Bumble",
      "Reglas",
      "Centro de ayuda",
      "Gestionar cookies",
      "Política de privacidad",
      "La aplicación",
      "Condiciones de Uso",
      "Acerca de",
      "Embajadores",
      "Blog",
      "Nuestra Tienda",
      "Descargar la aplicación",
      "Descargar para Android",
      "Descargar para iOS",
      "Lo sentimos, pero no pudimos encontrar la página que estabas buscando.",
      "Entrar",
      "Únete",
      "¡Únete a la Colmena!",
      "Ver todo →",
      "↓ Ver más",
      "Parece que algo falló..."
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "días"
            ],
            "singular": [
                  "día"
            ]
      },
      "hour": {
            "plural": [
                  "horas"
            ],
            "singular": [
                  "hora"
            ]
      },
      "minute": {
            "plural": [
                  "minutos"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "meses"
            ],
            "singular": [
                  "mes"
            ]
      },
      "second": {
            "plural": [
                  "segundos"
            ],
            "singular": [
                  "segundo"
            ]
      },
      "year": {
            "plural": [
                  "años"
            ],
            "singular": [
                  "año"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
