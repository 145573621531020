/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Pobierz Bumble",
      "Zaakceptuj wszystkie",
      "Korzystamy z plików cookie, aby usprawnić naszą stronę. Korzystamy z cookie analitycznych i reklamowych. Więcej informacji znajdziesz tu: <a p0>Polityka plików cookie</a>.",
      "Zarządzaj opcjami",
      "Powiedz nam czy zgadzasz się na cookie",
      "Przeczytaj więcej",
      "Wróć",
      "Nieprawidłowy numer telefonu",
      "© {{current_year}} Bumble | Wszelkie prawa zastrzeżone",
      "Kategorie",
      "Najnowsze",
      "Bumble Buzz",
      "Nasz blog The Buzz przeniósł się na nową witrynę. Zajrzyj, aby zasięgnąć porad dotyczących randek, przyjaźni, wellness i nie tylko: <a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>",
      "The Buzz zmienił adres!",
      "Praca w Bumble",
      "Nie sprzedawaj ani nie udostępniaj moich danych osobowych",
      "Obserwuj @{{account_name}}",
      "Kariera",
      "Przyjaźń",
      "Miłość",
      "Wyślij historię",
      "Historie sukcesu",
      "Samopoczucie",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Praca",
      "Firma",
      "Wydarzenia",
      "To najlepszy moment na poznanie nowych, inspirujących ludzi.",
      "Obserwuj Bumble",
      "Zasady użytkowania",
      "Pomoc",
      "Zarządzaj plikami cookie",
      "Polityka Prywatności",
      "Aplikacja",
      "Regulamin",
      "O nas",
      "Ambasadorzy marki",
      "Blog",
      "Sklep",
      "Pobierz aplikację",
      "Pobierz na Android",
      "Pobierz na iOS",
      "Przykro nam, ale nie nie możemy odnaleźć strony, której szukasz.",
      "Zaloguj się",
      "Dołącz",
      "Dołącz do nas!",
      "Zobacz wszystko →",
      "↓ Zobacz więcej",
      "Ojej, coś poszło nie tak"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "few": [
                  "dni",
                  "dni",
                  "dniom",
                  "dni",
                  "dniach",
                  "dniami",
                  "dni"
            ],
            "many": [
                  "dni",
                  "dni",
                  "dniom",
                  "dni",
                  "dniach",
                  "dniami",
                  "dni"
            ],
            "plural": [
                  "dni",
                  "dni",
                  "dni",
                  "dni",
                  "dni",
                  "dni",
                  "dni"
            ],
            "singular": [
                  "dzień",
                  "dnia",
                  "dniowi",
                  "dzień",
                  "dniu",
                  "dniem",
                  "dzień"
            ]
      },
      "hour": {
            "few": [
                  "godziny",
                  "godzin",
                  "godzinom",
                  "godziny",
                  "godzinach",
                  "godzinami",
                  "godziny"
            ],
            "many": [
                  "godzin",
                  "godzin",
                  "godzinom",
                  "godzin",
                  "godzinach",
                  "godzinami",
                  "godzin"
            ],
            "plural": [
                  "godzin",
                  "godzin",
                  "godzin",
                  "godzin",
                  "godzin",
                  "godzin",
                  "godzin"
            ],
            "singular": [
                  "godzina",
                  "godziny",
                  "godzinie",
                  "godzinę",
                  "godzinie",
                  "godziną",
                  "godzina"
            ]
      },
      "minute": {
            "few": [
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min."
            ],
            "many": [
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min."
            ],
            "plural": [
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min."
            ],
            "singular": [
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min.",
                  "min."
            ]
      },
      "month": {
            "few": [
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies."
            ],
            "many": [
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies."
            ],
            "plural": [
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies."
            ],
            "singular": [
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies.",
                  "mies."
            ]
      },
      "second": {
            "few": [
                  "sekundy",
                  "sekund",
                  "sekundom",
                  "sekundy",
                  "sekundach",
                  "sekundami",
                  "sekundy"
            ],
            "many": [
                  "sekund",
                  "sekund",
                  "sekundom",
                  "sekund",
                  "sekundach",
                  "sekundami",
                  "sekund"
            ],
            "plural": [
                  "sekund",
                  "sekund",
                  "sekund",
                  "sekund",
                  "sekund",
                  "sekund",
                  "sekund"
            ],
            "singular": [
                  "sekunda",
                  "sekundy",
                  "sekundzie",
                  "sekundę",
                  "sekundzie",
                  "sekundą",
                  "sekundo"
            ]
      },
      "year": {
            "few": [
                  "lata",
                  "lat",
                  "latom",
                  "lata",
                  "latach",
                  "latami",
                  "lata"
            ],
            "many": [
                  "lat",
                  "lat",
                  "latom",
                  "lat",
                  "latach",
                  "latami",
                  "lat"
            ],
            "plural": [
                  "lat",
                  "lat",
                  "lat",
                  "lat",
                  "lat",
                  "lat",
                  "lat"
            ],
            "singular": [
                  "rok",
                  "roku",
                  "rokowi",
                  "rok",
                  "roku",
                  "rokiem",
                  "rok"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
