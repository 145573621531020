/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Hanki Bumble",
      "Hyväksy kaikki",
      "Käytämme evästeitä sivuston toiminnan edistämiseksi. Evästeet sisältävät analytiikka- ja mainosevästeitä. Lisätietoa saa lukemalla <a p0>evästekäytäntömme</a>.",
      "Hallitse valintoja",
      "Vahvista, että hyväksyt evästeet",
      "Näytä lisää",
      "Mene takaisin",
      "Virheellinen puhelinnumero",
      "© {{current_year}} Bumble | Kaikki oikeudet pidätetään.",
      "Kategoriat",
      "Uusimmat",
      "Bumble Buzz",
      "Buzz on muuttanut uudelle nettisivulle. Kurkkaa täältä vinkkejä deittailuun, ystäväsuhteisiin, hyvään oloon ja muuhun: <a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>",
      "Buzzilla on uusi koti.",
      "Työpaikat Bumblessa",
      "Älä myy tai jaa henkilökohtaisia tietojani",
      "Seuraa @{{account_name}}",
      "Ura",
      "Ystävyys",
      "Rakkaus",
      "Jaa tarinasi",
      "Menestystarinat",
      "Hyvinvointi",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Työpaikat",
      "Yritys",
      "Tapahtumat",
      "Mikä ainutlaatuinen tilaisuus tavata uusia, hyvän itsetunnon omaavia ihmisiä.",
      "Seuraa Bumblea",
      "Käyttöohjeet",
      "Tukipalvelu",
      "Hallinnoi evästeitä",
      "Tietosuoja",
      "Appi",
      "Käyttöehdot",
      "Yleistä",
      "Tuote-edustajat",
      "Buzz",
      "Kauppa",
      "Lataa sovellus",
      "Lataa Android-appi",
      "Lataa iOS-appi",
      "Valitettavasti etsimääsi sivua ei löytynyt.",
      "Kirjaudu sisään",
      "Liity",
      "Liity pesään!",
      "Näytä kaikki →",
      "↓ Näe lisää",
      "No voi, jotain on vialla"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää",
                  "päivää"
            ],
            "singular": [
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä",
                  "päivä"
            ]
      },
      "hour": {
            "plural": [
                  "tuntia",
                  "tuntia",
                  "tunnin",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia",
                  "tuntia"
            ],
            "singular": [
                  "tunti",
                  "tunti",
                  "tunnin",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti",
                  "tunti"
            ]
      },
      "minute": {
            "plural": [
                  "minuuttia",
                  "minuuttia",
                  "minuutin",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia",
                  "minuuttia"
            ],
            "singular": [
                  "minuutti",
                  "minuutti",
                  "minuutin",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti",
                  "minuutti"
            ]
      },
      "month": {
            "plural": [
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta",
                  "kuukautta"
            ],
            "singular": [
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi",
                  "kuukausi"
            ]
      },
      "second": {
            "plural": [
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia",
                  "sekuntia"
            ],
            "singular": [
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti",
                  "sekunti"
            ]
      },
      "year": {
            "plural": [
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta",
                  "vuotta"
            ],
            "singular": [
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi",
                  "vuosi"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
