/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Bumble downloaden",
      "Alle akzeptieren",
      "Wir verwenden Cookies, um unsere Seite zu optimieren. Dazu gehören analytische und Werbe-Cookies. Weitere Informationen findest du in unserer <a p0>Cookie-Richtlinie</a>.",
      "Optionen verwalten",
      "Wie sieht's mit Cookies aus?",
      "Mehr lesen",
      "Zurück",
      "Telefonnummer fehlerhaft",
      "© {{current_year}} Bumble | Alle Rechte vorbehalten",
      "Kategorien",
      "Neue Einträge",
      "Bumble Buzz",
      "Der Blog ist umgezogen. Check die neue Website mit Tipps zu Dating, Freundschaften, Wellness und mehr: <a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>",
      "Der Blog ist umgezogen!",
      "Karriere bei Bumble",
      "Meine personenbezogenen Daten nicht verkaufen oder weitergeben",
      "Folge @{{account_name}}",
      "Karriere",
      "Freundschaft",
      "Liebe",
      "Story einreichen",
      "Erfolgsgeschichten",
      "Wellness",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Karriere",
      "Unternehmen",
      "Events",
      "Triff neue Leute und lass dich inspirieren – jetzt ist deine Zeit!",
      "Folge Bumble!",
      "Richtlinien",
      "Hilfebereich",
      "Cookies verwalten",
      "Datenschutz",
      "Die App",
      "AGB",
      "Über uns",
      "Ambassadors",
      "Blog",
      "Der Shop",
      "Jetzt App downloaden",
      "Zum Android-Download",
      "Zum iOS-Download",
      "Wir konnten die gesuchte Seite leider nicht finden.",
      "Einloggen",
      "Mitmachen",
      "Sei dabei!",
      "Alle ansehen →",
      "↓ Mehr ansehen",
      "Sorry, da ist was schiefgegangen!"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "Tage",
                  "Tage",
                  "Tagen",
                  "Tage"
            ],
            "singular": [
                  "Tag",
                  "Tags",
                  "Tag",
                  "Tag"
            ]
      },
      "hour": {
            "plural": [
                  "Stunden",
                  "Stunden",
                  "Stunden",
                  "Stunden"
            ],
            "singular": [
                  "Stunde",
                  "Stunde",
                  "Stunde",
                  "Stunde"
            ]
      },
      "minute": {
            "plural": [
                  "Minuten",
                  "Minuten",
                  "Minuten",
                  "Minuten"
            ],
            "singular": [
                  "Minute",
                  "Minute",
                  "Minute",
                  "Minute"
            ]
      },
      "month": {
            "plural": [
                  "Monate",
                  "Monate",
                  "Monaten",
                  "Monate"
            ],
            "singular": [
                  "Monat",
                  "Monats",
                  "Monat",
                  "Monat"
            ]
      },
      "second": {
            "plural": [
                  "Sekunden",
                  "Sekunden",
                  "Sekunden",
                  "Sekunden"
            ],
            "singular": [
                  "Sekunde",
                  "Sekunde",
                  "Sekunde",
                  "Sekunde"
            ]
      },
      "year": {
            "plural": [
                  "Jahre",
                  "Jahre",
                  "Jahren",
                  "Jahre"
            ],
            "singular": [
                  "Jahr",
                  "Jahres",
                  "Jahr",
                  "Jahr"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
