/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Få Bumble",
      "Accepter alle",
      "Vi bruger Cookies til at forbedre funktionaliteten af vores side. Dette inkluderer analyse-Cookies og annoncerings-Cookies. For mere information, se venligst vores <a p0>Cookie-politik</a>.",
      "administrer valgmuligheder",
      "Fortæl os, om du tillader Cookies",
      "Læs mere",
      "Tilbage",
      "Forkert telefonnummer",
      "© {{current_year}} Bumble | Alle rettigheder forbeholdes",
      "Kategorier",
      "Seneste",
      "Bumble Buzz",
      "Buzz er nu flyttet til et nyt website. Se det her, og få råd til dating, venskab, wellness og meget mere: <a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>",
      "Buzz har fået et nyt hjem!",
      "Jobs hos Bumble",
      "Sælg eller del ikke mine personlige informationer",
      "Følg @{{account_name}}",
      "Karriere",
      "Venskab",
      "Kærlighed",
      "Indsend en historie",
      "Succeshistorier",
      "Helse/velvære",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Jobmuligheder",
      "Firmaet",
      "Begivenheder",
      "Der har aldrig været et bedre tidspunkt at møde nye, spændende mennesker på.",
      "Følg Bumble",
      "Retningslinjer",
      "Hjælpecenter",
      "Administrer cookies",
      "Privatlivspolitik",
      "Appen",
      "Vilkår & betingelser",
      "Om",
      "Ambassadører",
      "Blog",
      "Shoppen",
      "Download appen",
      "Download til Android",
      "Download til iOS",
      "Beklager, men vi kan ikke finde den side, du ledte efter.",
      "Log ind",
      "Tilmeld",
      "Tilslut dig kuben!",
      "Se alle →",
      "↓ Se mere",
      "Hovsa, noget gik galt"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "dage",
                  "dages"
            ],
            "singular": [
                  "dag",
                  "dags"
            ]
      },
      "hour": {
            "plural": [
                  "timer",
                  "timers"
            ],
            "singular": [
                  "time",
                  "times"
            ]
      },
      "minute": {
            "plural": [
                  "minutter",
                  "minutters"
            ],
            "singular": [
                  "minut",
                  "minuts"
            ]
      },
      "month": {
            "plural": [
                  "måneder",
                  "måneders"
            ],
            "singular": [
                  "måned",
                  "måneds"
            ]
      },
      "second": {
            "plural": [
                  "sekunder",
                  "sekunders"
            ],
            "singular": [
                  "sekund",
                  "sekunds"
            ]
      },
      "year": {
            "plural": [
                  "år",
                  "årenes"
            ],
            "singular": [
                  "år",
                  "årets"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
