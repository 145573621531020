/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Bumble 다운받기",
      "모두 수락",
      "저희는 사이트를 원활하게 운영하기 위해 분석 쿠키와 광고 쿠키를 포함한 쿠키를 사용합니다. 당사의 <a p0>쿠키 정책</a>에서 더 자세한 정보를 확인하실 수 있습니다.",
      "옵션 관리하기",
      "쿠키 사용에 동의하시나요?",
      "더 읽기",
      "돌아가기",
      "올바르지 않은 전화번호",
      "© {{current_year}} Bumble | All Rights Reserved",
      "카테고리",
      "최신 포스트",
      "Bumble Buzz",
      "블로그는 이제 새로운 웹사이트로 옮겨졌습니다. 데이트, 우정, 웰빙 그리고 더 많은 것들에 대한 조언을 위해 <a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a> 을 확인하세요",
      "블로그에게 새로운 공간이 생겼어요!",
      "Bumble 에서의 커리어",
      "내 개인정보를 판매하거나 공유하지 않음",
      "팔로우하세요 @{{account_name}}",
      "커리어",
      "우정",
      "사랑",
      "회원님의 이야기",
      "성공 스토리",
      "웰빙",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "인재채용",
      "회사",
      "이벤트",
      "영감을 주는 새로운 사람들을 만날 수 있는 최고의 순간입니다.",
      "Bumble을\n팔로우하세요",
      "가이드라인",
      "헬프 데스크",
      "쿠키 관리",
      "개인정보 취급방침",
      "앱",
      "이용약관",
      "소개",
      "앰배서더",
      "블로그",
      "상점",
      "앱 다운로드",
      "Android용 다운로드",
      "iOS용 다운로드",
      "죄송합니다. 현재 찾으시는 페이지를 발견할 수 없습니다.",
      "로그인하기",
      "가입하기",
      "함께 즐겨보세요!",
      "모두 보기 →",
      "↓ 더 보기",
      "앗, 오류가 발생했어요"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "일"
            ],
            "singular": [
                  "일"
            ]
      },
      "hour": {
            "plural": [
                  "시간"
            ],
            "singular": [
                  "시간"
            ]
      },
      "minute": {
            "plural": [
                  "분"
            ],
            "singular": [
                  "분"
            ]
      },
      "month": {
            "plural": [
                  "개월"
            ],
            "singular": [
                  "개월"
            ]
      },
      "second": {
            "plural": [
                  "초"
            ],
            "singular": [
                  "초"
            ]
      },
      "year": {
            "plural": [
                  "년"
            ],
            "singular": [
                  "년"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
