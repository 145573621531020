/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Descargar Bumble",
      "Aceptar todos",
      "Utilizamos las cookies para hacer que la página funcione mejor. Esto incluye cookies analíticas y cookies de publicidad. Para más información, consulta nuestra <a p0>Política de Cookies</a>.",
      "Gestionar opciones",
      "Indícanos si aceptas las cookies",
      "Leer más",
      "Atrás",
      "Número de teléfono incorrecto",
      "@{{current_year}}Bumble | Todos los derechos reservados",
      "Categorías",
      "Más recientes",
      "Bumble Buzz",
      "El Blog se ha trasladado a un nuevo sitio web. Aquí encontrarás consejos sobre citas, amistad, bienestar y mucho más: <a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>",
      "¡El Blog tiene un nuevo hogar!",
      "Carreras en Bumble",
      "No vendan o compartan mi información personal",
      "Seguir @{{account_name}}",
      "Trayectoria profesional",
      "Amistad",
      "Amor",
      "Enviar história",
      "Historias de éxito",
      "Bienestar",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Empleo",
      "Quiénes somos",
      "Eventos",
      "Siempre es un buen momento para conocer gente que nos ayude a ser mejores.",
      "Seguir a Bumble",
      "Reglas",
      "Centro de ayuda",
      "Gestionar cookies",
      "Política de privacidad",
      "La aplicación",
      "Condiciones de Uso",
      "Acerca de",
      "Embajadores",
      "Blog",
      "Nuestra Tienda",
      "Descargar la aplicación",
      "Descargar para Android",
      "Descargar para iOS",
      "Lo sentimos, pero no pudimos encontrar la página que estabas buscando.",
      "Entrar",
      "Únete",
      "¡Únete a la Colmena!",
      "Ver todo →",
      "↓ Ver más",
      "Parece que algo falló..."
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "días"
            ],
            "singular": [
                  "día"
            ]
      },
      "hour": {
            "plural": [
                  "horas"
            ],
            "singular": [
                  "hora"
            ]
      },
      "minute": {
            "plural": [
                  "minutos"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "meses"
            ],
            "singular": [
                  "mes"
            ]
      },
      "second": {
            "plural": [
                  "segundos"
            ],
            "singular": [
                  "segundo"
            ]
      },
      "year": {
            "plural": [
                  "años"
            ],
            "singular": [
                  "año"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
