/**
 * Async script loader
 * @param  {String} src
 * @param  {String} id  Optional ID to prevent duplicate loading
 * @return {Promise}
 */
const loadScript = (function () {
  const loadPromises: { [key in string]?: Promise<string | void> } = {};

  return function (src: string, id: string) {
    // Don't reload existing scripts with ID's
    if (id && loadPromises[id]) {
      return loadPromises[id];
    }

    const scripts = global.document.getElementsByTagName("script")[0];

    const js = global.document.createElement("script");

    if (id) {
      js.id = id;
    }

    js.src = src;
    js.async = true;
    js.defer = true;

    let done = false;

    const promise: Promise<void> = new Promise(function (resolve, reject) {
      js.onload = function () {
        if (!done) {
          done = true;
          resolve();
        }
      };

      //@ts-ignore
      js.onreadystatechange = function () {
        //@ts-ignore
        if (!done && this.readyState === "complete") {
          done = true;
          resolve();
        }
      };

      js.onerror = function () {
        if (!done) {
          done = true;
          reject(new Error(`Failed to load the ${id} script.`));
        }
      };

      js.onabort = function () {
        if (!done) {
          done = true;
          reject(new Error(`Loading of the ${id} script has been aborted.`));
        }
      };
    });

    if (id) {
      loadPromises[id] = promise;
    }

    scripts?.parentNode?.insertBefore(js, scripts);

    return promise;
  };
})();

export default loadScript;
