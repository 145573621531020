/* eslint-disable */
export default {
      "share@NATIVE_SHARING_MW_LANDING_PAGE/ACTION": 0,
      "settings@cookies.banner.accept_cta": 1,
      "settings@cookies.banner.body": 2,
      "settings@cookies.banner.settings_cta": 3,
      "settings@cookies.banner.title": 4,
      "helpcenter@topic.link": 5,
      "onboarding@bumble.incognito.dismiss_warning.cta.cancel": 6,
      "signin@PHONE_INCORRECT": 7,
      "corp@copyright_notice": 8,
      "bumblesite@blog.categories.a11y.label": 9,
      "bumblesite@BLOG.CATEGORY_RECENT.TITLE": 10,
      "bumblesite@blog.main-page.title": 11,
      "bumblesite@buzz.migration.description": 12,
      "bumblesite@buzz.migration.title": 13,
      "bumblesite@careers.cta": 14,
      "bumblesite@ccpa_cta.title": 15,
      "bumblesite@INSTAGRAM.FOLLOW.TITLE": 16,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.CAREER": 17,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.FRIENDSHIP": 18,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.LOVE": 19,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.SUBMIT_STORY": 20,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.SUCCESS_STORIES": 21,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.WELLNESS": 22,
      "bumblesite@LAYOUT.FOOTER.BUMBLE_BFF": 23,
      "bumblesite@LAYOUT.FOOTER.BUMBLE_BIZZ": 24,
      "bumblesite@LAYOUT.FOOTER.BUMBLE_DATE": 25,
      "bumblesite@LAYOUT.FOOTER.CAREERS": 26,
      "bumblesite@LAYOUT.FOOTER.COMPANY": 27,
      "bumblesite@LAYOUT.FOOTER.EVENTS": 28,
      "bumblesite@LAYOUT.FOOTER.FOLLOW.CAPTION": 29,
      "bumblesite@LAYOUT.FOOTER.FOLLOW.TITLE": 30,
      "bumblesite@LAYOUT.FOOTER.GUIDELINES": 31,
      "bumblesite@LAYOUT.FOOTER.HELP_CENTER": 32,
      "bumblesite@LAYOUT.FOOTER.MANAGE_COOKIES": 33,
      "bumblesite@LAYOUT.FOOTER.PRIVACY_POLICY": 34,
      "bumblesite@LAYOUT.FOOTER.THEAPP": 35,
      "bumblesite@LAYOUT.FOOTER.TNC": 36,
      "bumblesite@LAYOUT.HEADER.ABOUT": 37,
      "bumblesite@LAYOUT.HEADER.AMBASSADORS": 38,
      "bumblesite@LAYOUT.HEADER.BEEHIVE": 39,
      "bumblesite@LAYOUT.HEADER.THE_SHOP": 40,
      "bma_client@dialog.photo_verification.not_access.download_app": 41,
      "bma_client@download_app_android": 42,
      "bma_client@download_app_ios": 43,
      "bma_client@pages.error.not-found.description": 44,
      "bma_client@pages.login-password.submit": 45,
      "bma_client@pages.registration.form.submit": 46,
      "bma_client@pages.registration.form.title": 47,
      "bma_client@see_all": 48,
      "bma_client@see_more": 49,
      "bma_client@video_chat.error_title": 50
} as const;
