/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Få Bumble",
      "Godta alle",
      "Vi bruker cookies for å gjøre nettsiden vår bedre. Dette inkluderer cookies for analyseverktøy og cookies for markedsføring. Sjekk <a p0>Cookies-reglementet</a> for mer informasjon.",
      "Administrer valg",
      "Godkjenn bruken av cookies",
      "Les mer",
      "Gå tilbake",
      "Feil telefonnummer",
      "© {{current_year}} Bumble | Alle rettigheter forbeholdes",
      "Kategorier",
      "Nylige",
      "Bumble Buzz",
      "The Buzz har flyttet til en ny nettside. Sjekk det ut for tips og råd om dating, vennskap, velvære og mer: <a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>",
      "The Buzz har et nytt hjem!",
      "Karrierer hos Bumble",
      "Ikke selg eller del min personlige informasjon",
      "Følg @{{account_name}}",
      "Karriere",
      "Vennskap",
      "Kjærlighet",
      "Send inn en historie",
      "Suksesshistorier",
      "Helse/velvære",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Jobbmuligheter",
      "Firmaet",
      "Eventer",
      "Tiden er inne for å treffe nye, spennende mennesker.",
      "Følg Bumble",
      "Retningslinjer",
      "Hjelpesenter",
      "Administrer cookies",
      "Personvern",
      "Appen",
      "Vilkår & Betingelser",
      "Om",
      "Ambassadører",
      "The Buzz",
      "The Shop",
      "Last ned appen",
      "Last ned for Android",
      "Last ned for iOS",
      "Vi beklager, vi kunne ikke finne siden du leter etter.",
      "Logg inn",
      "Bli med",
      "Bli med på Bumble!",
      "Se alle →",
      "↓ Se mer",
      "Oops, det oppsto en feil"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "dager"
            ],
            "singular": [
                  "dag"
            ]
      },
      "hour": {
            "plural": [
                  "timer"
            ],
            "singular": [
                  "time"
            ]
      },
      "minute": {
            "plural": [
                  "minutter"
            ],
            "singular": [
                  "minutt"
            ]
      },
      "month": {
            "plural": [
                  "måneder"
            ],
            "singular": [
                  "måned"
            ]
      },
      "second": {
            "plural": [
                  "sekunder"
            ],
            "singular": [
                  "sekund"
            ]
      },
      "year": {
            "plural": [
                  "år"
            ],
            "singular": [
                  "år"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
