/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Bumbleをゲット",
      "全て承認する",
      "サイトのより円滑な運営のためにクッキーを使用しており、分析のためのクッキーと広告のためのクッキーがそれに含まれます。詳しくは<a p0>クッキーポリシー</a>をご確認ください。",
      "オプションを管理",
      "クッキーに同意していただけますか？",
      "もっと読む",
      "戻る",
      "電話番号が間違っています",
      "©{{current_year}}Bumble | 不許複製・禁無断転載",
      "カテゴリー",
      "最近の項目",
      "Bumble Buzz",
      "Buzz は新しいウェブサイトに移転しました。出会い、友達関係、健全性等についてのアドバイスはこちらをチェック： <a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>。",
      "Buzzが生まれ変わりました！",
      "Bumbleの採用情報",
      "個人情報を販売または共有しない",
      "フォロー@{{account_name}}",
      "キャリア",
      "友情",
      "Love",
      "エピソードを投稿",
      "サクセスストーリー",
      "ウェルネス",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "採用情報",
      "会社",
      "イベント",
      "活気あふれる人たちと新しく出会うには、今が最高の時です。",
      "Bumbleをフォローする",
      "ガイドライン",
      "ヘルプセンター",
      "Cookieの管理",
      "プライバシーポリシー",
      "アプリ",
      "利用規約",
      "概要",
      "アンバサダー",
      "ブログ",
      "ショップ",
      "アプリをダウンロード",
      "Android版をダウンロード",
      "iOS版をダウンロード",
      "申し訳ありません、お探しのページが見つかりません。",
      "ログイン",
      "参加",
      "仲間に入ろう！",
      "全て見る→",
      "↓ もっと見る",
      "問題が発生しました"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "日"
            ],
            "singular": [
                  "日"
            ]
      },
      "hour": {
            "plural": [
                  "時間"
            ],
            "singular": [
                  "時間"
            ]
      },
      "minute": {
            "plural": [
                  "分"
            ],
            "singular": [
                  "分"
            ]
      },
      "month": {
            "plural": [
                  "ヶ月"
            ],
            "singular": [
                  "ヶ月"
            ]
      },
      "second": {
            "plural": [
                  "秒"
            ],
            "singular": [
                  "秒"
            ]
      },
      "year": {
            "plural": [
                  "年"
            ],
            "singular": [
                  "年"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
