import * as React from "react";
import Head from "next/head";

export function Styles() {
  return (
    <Head>
      <link
        id="deferred-styles"
        rel="preload"
        href="/the-buzz/fonts.css"
        as="style"
      />
    </Head>
  );
}

export function PreloadViewportUnitsBuggyfill() {
  return (
    <Head>
      <script async={true} src="/the-buzz/viewport-units-buggyfill.js"></script>
    </Head>
  );
}

export function FontsCircular() {
  return (
    <Head>
      <link
        id="deferred-styles"
        rel="preload"
        // cache invalidation
        href="/the-buzz/fonts/Circular20/fonts-circular20.css?v=4"
        as="style"
      />
    </Head>
  );
}

export function AmpStyles() {
  const domain =
    process.env.NODE_ENV === "production"
      ? "https://bumble.com"
      : "http://localhost:8080";

  return (
    // eslint-disable-next-line react/no-unknown-property
    <style global={true} jsx={true}>{`
      @font-face {
        font-family: "Circular20WebCyr-Book";
        src: url("${domain}/the-buzz/fonts/Circular20/Circular20WebCyr-Book.woff")
          format("woff");
      }
      @font-face {
        font-family: "Circular20WebCyr-Book";
        src: url("${domain}/the-buzz/fonts/Circular20/Circular20WebCyr-Book.woff2")
          format("woff2");
      }
      body {
        -webkit-font-smoothing: antialiased;
        -webkit-box-direction: normal;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
      :root {
        --horizontal-gap-sm: 8px;
        --horizontal-gap-md: 16px;
        --horizontal-gap-lg: 32px;
        --vertical-gap-sm: 8px;
        --vertical-gap-md: 16px;
        --vertical-gap-lg: 32px;
        --color-base: #454650;
        --font-family-base: "Circular20WebCyr-Book", "Helvetica Neue", Georgia,
          Times, serif;
        --font-weight-regular: normal;
        --font-weight-medium: 500;
        --font-weight-bold: bold;
        --font-size-base: 16px;
        --line-height-base: 1.5;
        --base-control-size: 40px;
        --header-height: 70px;
      }
      .theme-dating {
        --color-primary-light: #fff7d8;
        --color-primary: #ffc629;
        --color-primary-accent: #454650;
      }
      .theme-bff {
        --color-primary-light: #ecf8f5;
        --color-primary: #009abf;
        --color-primary-accent: #143251;
      }
      .theme-bizz {
        --color-primary-light: #f8dfd9;
        --color-primary: #ff6c37;
        --color-primary-accent: #562734;
      }
      html {
        color: var(--color-base);
        font-weight: var(--font-weight-regular);
        font-size: var(--font-size-base);
        line-height: var(--line-height-base);
        font-family: var(--font-family-base);
      }
      header,
      article,
      div,
      span,
      section,
      aside,
      nav,
      ul,
      ol {
        box-sizing: border-box;
      }
      h1,
      h2,
      h3,
      p,
      ol,
      ul,
      li {
        margin: 0;
        padding: 0;
      }
      strong,
      h2,
      h3 {
        font-weight: var(--font-weight-regular);
      }
      .formatted-text h2,
      .formatted-text h3,
      .formatted-text ul,
      .formatted-text ol,
      .formatted-text table,
      .formatted-text p {
        margin-bottom: var(--vertical-gap-md);
      }
      .formatted-text p > a,
      .formatted-text i a,
      .formatted-text em a,
      .formatted-text b a,
      .formatted-text strong a,
      .formatted-text li > a,
      .formatted-text td > a {
        color: var(--color-primary);
      }
      .formatted-text ul,
      .formatted-text ol {
        padding-left: var(--horizontal-gap-lg);
      }
      .formatted-text li {
        margin-bottom: var(--vertical-gap-sm);
      }
      .formatted-text__media + h2,
      .formatted-text__media + h3,
      .formatted-text__media + h4,
      .formatted-text__media + p,
      .formatted-text__media + ol,
      .formatted-text__media + ul,
      .formatted-text__media + table,
      .formatted-text__media + .formatted-text__media {
        margin-top: 32px;
      }

      h2 + .formatted-text__media,
      h3 + .formatted-text__media,
      h4 + .formatted-text__media,
      p + .formatted-text__media,
      ol + .formatted-text__media,
      ul + .formatted-text__media,
      table + .formatted-text__media,
      .formatted-text__media + .formatted-text__media {
        margin-top: 32px;
      }
      .formatted-text__media--centered {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .visible-on-android {
        display: block;
      }
      .visible-on-ios {
        display: none;
      }
      .ios .visible-on-android {
        display: none;
      }
      .ios .visible-on-ios {
        display: block;
      }
      .page {
        display: flex;
        flex-direction: column;
      }
      .page__header {
        height: var(--header-height);
      }
      .page__share {
        display: flex;
        justify-content: center;
      }
      .page__article,
      .page__share,
      .page__related-articles {
        margin-bottom: var(--vertical-gap-lg);
      }
      .header {
        display: flex;
        align-items: center;
        height: inherit;
        padding: var(--vertical-gap-sm) var(--horizontal-gap-md);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background: #fff;
      }
      .header__logo {
        flex: 1 0 auto;
      }
      .header__logo + .header__action {
        margin-left: 32px;
      }
      .header__actions {
        display: flex;
        align-items: center;
      }
      .header__actions-item {
        list-style: none;
      }
      .header__actions-item + .header__actions-item {
        margin-left: var(--horizontal-gap-md);
      }
      .sidebar {
        background: var(--color-primary-light);
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-bottom: var(--vertical-gap-md);
      }
      .sidebar__header {
        height: var(--header-height);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: var(--vertical-gap-sm) var(--horizontal-gap-md);
      }
      .sidebar__content {
        flex: 1 0 auto;
      }
      .sidebar__action {
        display: flex;
        justify-content: center;
        padding: var(--vertical-gap-lg) var(--horizontal-gap-md);
      }
      .icon {
        position: relative;
        display: block;
        width: 24px;
        height: 24px;
        fill: currentColor;
        -webkit-font-smoothing: antialiased;
      }
      .icon__content {
        width: inherit;
        height: inherit;
        fill: inherit;
        pointer-events: none;
      }
      .button {
        display: block;
        max-width: 100%;
        height: var(--base-control-size);
        margin: 0;
        padding: 0 32px;
        outline: 0;
        border: none;
        background: transparent;
        border-radius: calc(var(--base-control-size) / 2);
        color: var(--color-base);
        text-decoration: none;
        transition: 0.2s ease opacity;
        cursor: pointer;
        user-select: none;
        -webkit-font-smoothing: antialiased;
      }
      .button__content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: inherit;
      }
      .button__text {
        display: block;
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: var(--font-weight-bold);
      }
      .button--circle {
        width: var(--base-control-size);
        padding: 0;
      }
      .button--primary {
        background: var(--color-primary);
      }
      .logotype {
        color: var(--color-primary);
      }
      .logotype__image {
        display: block;
        width: 127px;
        height: 21px;
        fill: currentColor;
      }
      .mobile-menu,
      .mobile-submenu {
        display: block;
        padding-left: var(--horizontal-gap-md);
      }
      .mobile-menu__link {
        font-weight: var(--font-weight-medium);
      }
      .mobile-menu__link,
      .mobile-submenu__link {
        color: inherit;
        text-decoration: none;
      }
      .mobile-menu__link + .mobile-menu__link,
      .mobile-menu__link + .mobile-menu__submenu {
        margin-top: var(--vertical-gap-md);
      }
      .mobile-menu + .mobile-menu {
        margin-top: var(--vertical-gap-lg);
      }
      .article {
        padding: 0 var(--horizontal-gap-md);
      }
      .article__category {
        color: var(--color-primary);
        font-weight: var(--font-weight-medium);
      }
      .article__title {
        color: var(--color-primary-accent);
        font-weight: var(--font-weight-medium);
        font-size: 26px;
        line-height: 1.3;
      }
      .article__splashscreen,
      .article__title,
      .article__category,
      .article__content {
        margin-bottom: var(--vertical-gap-md);
      }
      .share {
        display: flex;
        align-items: center;
      }
      .share__item + .share__item {
        margin-left: var(--horizontal-gap-sm);
      }
      .share__item amp-social-share {
        border-radius: 50%;
      }
      .related-articles {
        padding: 0 var(--horizontal-gap-md);
      }
      .related-articles__title + .related-articles__list {
        margin-top: var(--vertical-gap-md);
      }
      .related-articles__item + .related-articles__item {
        border-top: 1px solid var(--color-accent);
        margin-top: var(--vertical-gap-md);
        padding-top: var(--vertical-gap-md);
      }
      .related-article {
        display: flex;
        text-decoration: none;
        align-items: center;
      }
      .related-article__title {
        color: var(--color-primary);
        font-size: var(--font-size-base);
      }
      .join-the-hive {
        display: flex;
        flex-direction: column;
      }
      .join-the-hive__title {
        align-self: center;
        max-width: 300px;
        text-align: center;
        font-size: 32px;
        line-height: 1.4;
      }
      .join-the-hive__title-text {
        background-image: linear-gradient(
          to right,
          var(--color-primary) 0%,
          var(--color-primary) 100%
        );
        background-position: 0 80%;
        background-repeat: repeat-x;
        background-size: 100% 8px;
      }
      .join-the-hive__download-app {
        display: flex;
        justify-content: center;
      }
      .join-the-hive__title + .join-the-hive__download-app {
        margin-top: var(--vertical-gap-md);
      }
      .download-app {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .download-app__item + .download-app__item {
        margin-top: 12px;
      }

      .formatted-text__media-amp-img {
        position: relative;
        height: 50vh;
        background-color: var(--color-primary-light);
      }

      .formatted-text__media-amp-img img {
        object-fit: contain;
      }

      .wp-amp-iframe amp-iframe {
        margin: 16px auto;
        height: 50vh;
      }

      .article__amp-img {
        position: relative;
        height: 33.333vh; /* 66.67 aspect ratio */
        background-color: var(--color-primary-light);
      }

      .article__amp-img img {
        object-fit: contain;
      }

      .article__amp-img + .article__category {
        margin-top: 32px;
      }

      figure {
        margin: 16px 0;
      }

      // .splash-screen--blocker for amp-articles
      .splash-screen--blocker {
        display: flex;
        align-items: center;
        height: 100%;
        width: auto;
        margin-left: calc(-1 * var(--horizontal-gap-md));
        margin-right: calc(-1 * var(--horizontal-gap-md));
        padding: var(--horizontal-gap-md);
        background-color: var(--color-primary-light);
      }

      .splash-screen__blocker-message-title {
        font-size: 26px;
        line-height: 1.3;
        margin-bottom: 12px;
      }

      .splash-screen__blocker-message-description a {
        color: inherit;
      }
    `}</style>
  );
}
