/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Télécharger Bumble",
      "Tout accepter",
      "Nous utilisons des cookies pour assurer le bon fonctionnement de nos services, dont des cookies analytiques et des cookies publicitaires. Pour plus d'informations, consultez notre <a p0>Politique des Cookies</a>.",
      "Gérer les options",
      "Un p'tit cookie ?",
      "Lire la suite",
      "Retour",
      "Numéro de téléphone incorrect",
      "© {{current_year}} Bumble | Tous droits réservés.",
      "Catégories",
      "Derniers articles",
      "Bumble Buzz",
      "Le blog est à présent sur un nouveau site Web. Tu y trouveras des conseils sur les rencontres, l'amitié, le bien-être et bien plus encore : <a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>",
      "Le blog a déménagé !",
      "Carrières chez Bumble",
      "Ne pas vendre ou partager mes données personnelles",
      "Suivre @{{account_name}}",
      "Carrière",
      "Amitié",
      "Amour",
      "Envoyer un témoignage",
      "Témoignages",
      "Bien-être",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Emploi",
      "La société",
      "Événements",
      "Il n'y a jamais eu de meilleur moment pour faire des rencontres dignes de ce nom.",
      "Suivre Bumble",
      "Charte",
      "Aide",
      "Gérer les cookies",
      "Confidentialité",
      "L'appli",
      "Conditions",
      "À propos",
      "Ambassadeurs",
      "Le Blog",
      "Boutique",
      "Télécharger l'appli",
      "Télécharger pour Android",
      "Télécharger pour iOS",
      "Cette page est introuvable.",
      "Se connecter",
      "S'inscrire",
      "Bienvenue !",
      "Voir tout →",
      "↓ Voir plus",
      "Une erreur est survenue"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "jours"
            ],
            "singular": [
                  "jour"
            ]
      },
      "hour": {
            "plural": [
                  "heures"
            ],
            "singular": [
                  "heure"
            ]
      },
      "minute": {
            "plural": [
                  "minutes"
            ],
            "singular": [
                  "minute"
            ]
      },
      "month": {
            "plural": [
                  "mois"
            ],
            "singular": [
                  "mois"
            ]
      },
      "second": {
            "plural": [
                  "secondes"
            ],
            "singular": [
                  "seconde"
            ]
      },
      "year": {
            "plural": [
                  "ans"
            ],
            "singular": [
                  "an"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
