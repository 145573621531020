/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Instalar o Bumble",
      "Aceitar tudo",
      "Utilizamos cookies para melhorar o funcionamento do site. Isso inclui cookies analíticos e cookies de publicidade. Para mais informações, consulte a nossa <a p0>Política de Cookies</a>",
      "Gerir opções",
      "Diga-nos se concorda com os nossos cookies",
      "Ler mais",
      "Voltar",
      "Número de telefone incorreto",
      "© {{current_year}} Bumble | Todos os direitos reservados.",
      "Categorias",
      "Mais recentes",
      "Bumble Buzz",
      "O The Buzz mudou-se para um novo website. Dá uma vista de olhos aqui para conselhos sobre encontros, amizades, bem-estar e muito mais: <a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>",
      "O The Buzz mudou-se!",
      "Empregos no Bumble",
      "Não vender nem partilhar as minhas informações pessoais",
      "Segue o @{{account_name}}",
      "Carreira",
      "Amizade",
      "Amor",
      "Enviar história",
      "Histórias de Sucesso",
      "Bem-estar",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Vagas",
      "Empresa",
      "Eventos",
      "Nunca houve um momento melhor para conhecer pessoas novas e inspiradoras.",
      "Seguir Bumble",
      "Regras",
      "Centro de Ajuda",
      "Gerir Cookies",
      "Política de Privacidade",
      "App",
      "Termos e Condições",
      "Sobre",
      "Embaixadores",
      "O Buzz",
      "Loja",
      "Instalar a App",
      "Instalar para Android",
      "Instalar para iOS",
      "Página não encontrada.",
      "Entrar",
      "Entrar",
      "Junta-te à Colmeia!",
      "Ver tudo →",
      "↓ Ver mais",
      "Ups, ocorreu um problema"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "dias"
            ],
            "singular": [
                  "dia"
            ]
      },
      "hour": {
            "plural": [
                  "horas"
            ],
            "singular": [
                  "hora"
            ]
      },
      "minute": {
            "plural": [
                  "minutos"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "meses"
            ],
            "singular": [
                  "mês"
            ]
      },
      "second": {
            "plural": [
                  "segundos"
            ],
            "singular": [
                  "segundo"
            ]
      },
      "year": {
            "plural": [
                  "anos"
            ],
            "singular": [
                  "ano"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
