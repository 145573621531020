import { Provider as LocaleProvider } from "components/locale-context";
import { Provider as ModalProvider } from "components/modal-context";
import localization from "lib/localization";
import { AmpStyles } from "lib/scripts";
import useBodyOSClasses from "lib/use-body-os-classes";
import { Localization } from "@bumble/localization/components";
import { DefaultSeo } from "next-seo";
import { useAmp } from "next/amp";
import type { AppProps } from "next/app";
import { getCanonical, getLanguageAlternates } from "components/seo";
import React, { useEffect } from "react";
import supportedLanguageIds, {
  SupportedLanguage,
} from "lib/lexemes/supported-languages";
import { AppError } from "lib/app-error";
import {
  onUseCookieConsentEvent,
  onUsePrivacyManagerHandler,
} from "lib/cookie-consent";

// import "../styles/base.scss";
import "../styles/style.min.css";
import { FetchedLanguageId } from "@bumble/localization/types";
// @ts-ignore
import { ErrorBoundary } from "@bumble/react-error-boundary";
import { logger, useGelatoLogger } from "../lib/use-gelato-logger";
import { SOCIAL_IMAGE_FALLBACK } from "lib/constants";

// eslint-disable-next-line max-statements, complexity
function App({ Component, router, pageProps }: AppProps) {
  const lang = getDefaultLanguage(pageProps.lang);
  const isAmp = useAmp();
  const lexemes = getLang(lang);

  useBodyOSClasses();
  useLazyLoadCss();
  useGelatoLogger();

  useEffect(() => {
    if (!isAmp) {
      onUseCookieConsentEvent();
    }
  }, [isAmp]);
  useEffect(() => {
    if (!isAmp) {
      onUsePrivacyManagerHandler();
    }
  }, [isAmp]);

  let title = undefined;
  let description = undefined;
  let imageSrc = SOCIAL_IMAGE_FALLBACK;
  let altText = undefined;
  let imageWidth = 1200;
  let imageHeight = 603;
  const canonical = getCanonical(router);
  const languageAlternates = getLanguageAlternates(router);

  if (pageProps.pageType === "Tag" || pageProps.pageType === "Category") {
    if (pageProps.title) {
      title = pageProps.title;
    } else if (pageProps.recentPosts?.name) {
      title = `${pageProps.recentPosts?.name} Archives - Bumble Buzz`;
    }
    if (pageProps.description) {
      description = pageProps.description;
    }
    if (pageProps.imageSrc) {
      imageSrc = pageProps.imageSrc;
    }
  } else if (pageProps.pageType === "Post") {
    if (pageProps.imageSrc) {
      imageSrc = pageProps.imageSrc;
    }
    title = pageProps?.post?.seo?.title || pageProps?.post?.title;
    description = pageProps?.post?.seo?.metaDesc;
    altText = pageProps?.post?.seo?.title || pageProps?.post?.title;

    if (
      pageProps.post?.seo?.opengraphImage &&
      pageProps?.post?.seo?.opengraphImage?.altText &&
      pageProps?.post?.seo?.opengraphImage?.altText?.length > 0
    ) {
      altText = pageProps?.post?.seo?.opengraphImage.altText;
    }
    if (pageProps?.post?.seo?.opengraphImage?.mediaDetails?.width) {
      imageWidth = pageProps?.post?.seo?.opengraphImage.mediaDetails?.width;
    }
    if (pageProps?.post?.seo?.opengraphImage?.mediaDetails?.height) {
      imageHeight = pageProps?.post?.seo?.opengraphImage.mediaDetails?.height;
    }
  }

  return (
    <ErrorBoundary
      debug={false}
      logger={(error, errorDetails) => {
        if (process.env.NODE_ENV === "production") {
          logger.trackError(error, {
            ...errorDetails,
            origin: "ErrorBoundary",
            debugInfo: errorDetails?.debug_info,
          });
        }
      }}
    >
      <LocaleProvider value={lang}>
        {/* @ts-ignore - @TODO - update in  */}
        <Localization
          instance={localization}
          lang={lang as FetchedLanguageId}
          entries={lexemes.lexemes}
          commonWords={lexemes.common}
        >
          {/* Use appType to switch between different SEO tags */}
          <DefaultSeo
            title={title}
            description={description}
            languageAlternates={languageAlternates}
            canonical={canonical}
            facebook={{
              appId: "428250913904849",
            }}
            additionalMetaTags={[
              {
                name: "apple-itunes-app",
                content: "app-id=930441707",
              },
              {
                property: "p:domain_verify",
                content: "26cd87d4b7db08a05bdfa2219d3671b6",
              },
              {
                property: "referrer",
                content: "origin-when-cross-origin",
              },
              {
                property: "twitter:app:name:iphone",
                content: "Bumble - Changing the rules of the game.",
              },
              {
                property: "twitter:app:id:iphone",
                content: "930441707",
              },
            ]}
            openGraph={{
              type: "website",
              locale: lang,
              url: canonical,
              site_name: "Bumble Buzz",
              images: [
                {
                  url: imageSrc,
                  width: imageWidth,
                  height: imageHeight,
                  alt: altText || title || "Bumble Buzz",
                },
              ],
            }}
            twitter={{
              handle: "@bumble",
              cardType: "summary_large_image",
              site: "@bumble",
            }}
          />
          <ModalProvider>
            {isAmp ? <AmpStyles /> : null}
            <Component {...pageProps} />
          </ModalProvider>
        </Localization>
      </LocaleProvider>
    </ErrorBoundary>
  );
}

export default App;

function getDefaultLanguage(lang: string): SupportedLanguage {
  if (supportedLanguageIds.includes(lang as SupportedLanguage)) {
    return lang as SupportedLanguage;
  } else if (typeof lang === "string") {
    // We have a language being passed, but it's not valid, it means user is on an invalid url
    // e.g. /xx/the-buzz or team.bumble.com/xx/open-roles
    throw new AppError({
      statusCode: 404,
      message: `Invalid page language - ${lang}`,
    });
  }

  return "en";
}

function getLang(lang: SupportedLanguage) {
  return require(`lib/lexemes/${lang}`).default;
}

/**
 * Uses "Google approved" way of lazy loading CSS
 * 'deferred-styles' is a noscript tag embedded in the page which the browser doesn't execute for normal environments
 * We just read it and re-add it to the body
 */
function useLazyLoadCss() {
  useEffect(() => {
    const addStylesNode = global.document.getElementById("deferred-styles");
    if (!addStylesNode) {
      return;
    }

    const linkEl = global.document.createElement("link");
    linkEl.rel = "stylesheet";
    linkEl.href = addStylesNode.getAttribute("href") || "";
    global.document.body.appendChild(linkEl);
  }, []);
}
