/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Скачать Bumble",
      "Принять все",
      "Для того, чтобы наш сайт работал лучше, мы используем файлы cookie, в том числе аналитические и рекламные. Ты можешь узнать больше, ознакомившись с нашей <a p0>Политикой использования файлов cookie</a>.",
      "Изменить настройки",
      "Как насчет cookies?",
      "Читать дальше",
      "Вернуться",
      "Неверный номер",
      "© Bumble, {{current_year}} | Все права защищены",
      "Категории",
      "Свежие публикации",
      "Bumble Buzz",
      "Мы перенесли наш блог на новый веб-сайт. Теперь советы и статьи об отношениях, дружбе и хорошем самочувствии живут по адресу <a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>. Заходите в гости!",
      "Наш блог переехал!",
      "Вакансии в Bumble",
      "Не продавать и не передавать мою личную информацию",
      "Подпишитесь на @{{account_name}}",
      "Карьера",
      "Дружба",
      "Любовь",
      "Ваша история",
      "Истории знакомств",
      "Здоровье",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Карьера",
      "Компания",
      "События",
      "Время знакомиться с невероятными людьми!",
      "Подпишитесь на Bumble",
      "Правила",
      "Служба поддержки",
      "Настройки cookie",
      "Конфиденциальность",
      "Приложение",
      "Условия обслуживания",
      "О нас",
      "Амбассадоры",
      "Блог",
      "Магазин",
      "Скачать приложение",
      "Скачать для Android",
      "Скачать для iOS",
      "Страница не найдена :(",
      "Войти",
      "Присоединиться",
      "Присоединяйтесь!",
      "Показать всё →",
      "↓ Ещё",
      "Кажется, что-то пошло не так"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "few": [
                  "дня",
                  "дней",
                  "дням",
                  "дня",
                  "днями",
                  "днях"
            ],
            "many": [
                  "дней",
                  "дней",
                  "дням",
                  "дней",
                  "днями",
                  "днях"
            ],
            "plural": [
                  "дня",
                  "дней",
                  "дням",
                  "дня",
                  "днями",
                  "днях"
            ],
            "singular": [
                  "день",
                  "дня",
                  "дню",
                  "день",
                  "днём",
                  "дне"
            ],
            "two": [
                  "дня",
                  "дней",
                  "дням",
                  "дня",
                  "днями",
                  "днях"
            ]
      },
      "hour": {
            "few": [
                  "часа",
                  "часов",
                  "часам",
                  "часа",
                  "часами",
                  "часах"
            ],
            "many": [
                  "часов",
                  "часов",
                  "часам",
                  "часов",
                  "часами",
                  "часах"
            ],
            "plural": [
                  "часа",
                  "часов",
                  "часам",
                  "часа",
                  "часами",
                  "часах"
            ],
            "singular": [
                  "час",
                  "часа",
                  "часу",
                  "час",
                  "часом",
                  "часе"
            ],
            "two": [
                  "часа",
                  "часов",
                  "часам",
                  "часа",
                  "часами",
                  "часах"
            ]
      },
      "minute": {
            "few": [
                  "минуты",
                  "минут",
                  "минутам",
                  "минуты",
                  "минутами",
                  "минутах"
            ],
            "many": [
                  "минут",
                  "минут",
                  "минутам",
                  "минут",
                  "минутами",
                  "минутах"
            ],
            "plural": [
                  "минуты",
                  "минут",
                  "минутам",
                  "минуты",
                  "минутами",
                  "минутах"
            ],
            "singular": [
                  "минута",
                  "минуты",
                  "минуте",
                  "минуту",
                  "минутой",
                  "минуте"
            ],
            "two": [
                  "минуты",
                  "минут",
                  "минутам",
                  "минуты",
                  "минутами",
                  "минутах"
            ]
      },
      "month": {
            "few": [
                  "месяца",
                  "месяцев",
                  "месяца",
                  "месяца",
                  "месяцами",
                  "месяцах"
            ],
            "many": [
                  "месяцев",
                  "месяцев",
                  "месяцев",
                  "месяцев",
                  "месяцами",
                  "месяцах"
            ],
            "plural": [
                  "месяца",
                  "месяцев",
                  "месяца",
                  "месяца",
                  "месяцами",
                  "месяцах"
            ],
            "singular": [
                  "месяц",
                  "месяца",
                  "месяц",
                  "месяц",
                  "месяцем",
                  "месяце"
            ],
            "two": [
                  "месяца",
                  "месяцев",
                  "месяца",
                  "месяца",
                  "месяцами",
                  "месяцах"
            ]
      },
      "second": {
            "few": [
                  "секунды",
                  "секунд",
                  "секундам",
                  "секунды",
                  "секундами",
                  "секундах"
            ],
            "many": [
                  "секунд",
                  "секунд",
                  "секундам",
                  "секунд",
                  "секундами",
                  "секундах"
            ],
            "plural": [
                  "секунды",
                  "секунд",
                  "секундам",
                  "секунды",
                  "секундами",
                  "секундах"
            ],
            "singular": [
                  "секунда",
                  "секунды",
                  "секунде",
                  "секунду",
                  "секундой",
                  "секунде"
            ],
            "two": [
                  "секунды",
                  "секунд",
                  "секундам",
                  "секунды",
                  "секундами",
                  "секундах"
            ]
      },
      "year": {
            "few": [
                  "года",
                  "лет",
                  "годам",
                  "года",
                  "годами",
                  "годах"
            ],
            "many": [
                  "лет",
                  "лет",
                  "годам",
                  "лет",
                  "годами",
                  "годах"
            ],
            "plural": [
                  "года",
                  "лет",
                  "годам",
                  "года",
                  "годами",
                  "годах"
            ],
            "singular": [
                  "год",
                  "года",
                  "году",
                  "год",
                  "годом",
                  "годе"
            ],
            "two": [
                  "года",
                  "лет",
                  "годам",
                  "года",
                  "годами",
                  "годах"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
