/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Downloaden",
      "Cookies accepteren",
      "Met cookies werkt onze site beter. We maken gebruik van analytische cookies en reclamecookies. Lees voor meer informatie ons <a p0>Cookiebeleid</a>.",
      "Cookie-instellingen",
      "Geef je toestemming voor cookies",
      "Meer lezen",
      "Annuleren",
      "Onjuist telefoonnummer",
      "© {{current_year}} Bumble | Alle rechten voorbehouden",
      "Categorieën",
      "Nieuwe artikelen",
      "Bumble Buzz",
      "The Buzz is verplaatst naar een nieuwe website. Bekijk deze hier voor advies over daten, vriendschappen, gezondheid en meer: <a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>",
      "The Buzz is verhuisd!",
      "Werken bij Bumble",
      "Mijn persoonsgegevens niet verkopen of delen",
      "Volg @{{account_name}}",
      "Carrière",
      "Vriendschap",
      "Liefde",
      "Vertel jouw verhaal",
      "Succesverhalen",
      "Wellness",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Carrière",
      "Het bedrijf",
      "Evenementen",
      "Neem het initiatief en leer inspirerende mensen kennen",
      "Volg Bumble",
      "Richtlijnen",
      "Helpcentrum",
      "Cookies beheren",
      "Privacybeleid",
      "De app",
      "Algemene voorwaarden",
      "Over",
      "Ambassadeurs",
      "Blog",
      "De winkel",
      "Download de app",
      "Downloaden voor Android",
      "Downloaden voor iOS",
      "Helaas is de pagina die je zoekt niet te vinden",
      "Inloggen",
      "Aanmelden",
      "Doe mee!",
      "Alles bekijken →",
      "↓ Meer bekijken",
      "Oeps, er ging iets fout"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "dagen"
            ],
            "singular": [
                  "dag"
            ]
      },
      "hour": {
            "plural": [
                  "uur"
            ],
            "singular": [
                  "uur"
            ]
      },
      "minute": {
            "plural": [
                  "minuten"
            ],
            "singular": [
                  "minuut"
            ]
      },
      "month": {
            "plural": [
                  "maanden"
            ],
            "singular": [
                  "maand"
            ]
      },
      "second": {
            "plural": [
                  "seconden"
            ],
            "singular": [
                  "seconde"
            ]
      },
      "year": {
            "plural": [
                  "jaar"
            ],
            "singular": [
                  "jaar"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
