/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Get Bumble",
      "Accept All",
      "We use cookies to make our site work better. This includes analytics cookies and advertising cookies. For more information, please check our <a p0>Cookie Policy</a>.",
      "Manage Options",
      "Let us know you agree to cookies",
      "Read more",
      "Go back",
      "Incorrect phone number",
      "© {{current_year}} Bumble | All Rights Reserved",
      "Categories",
      "Most recent",
      "Bumble Buzz",
      "The Buzz has now moved to a new website. Check it out here for advice on dating, friendship, wellness, and more: <a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>",
      "The Buzz has a new home!",
      "Careers at Bumble",
      "Do not sell or share my personal information",
      "Follow @{{account_name}}",
      "Career",
      "Friendship",
      "Love",
      "Submit a story",
      "Success Stories",
      "Wellness",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble Date",
      "Careers",
      "The company",
      "Events",
      "There’s never been a better moment to meet new, empowering people.",
      "Follow Bumble",
      "Guidelines",
      "Help Center",
      "Manage Cookies",
      "Privacy Policy",
      "The app",
      "Terms & Conditions",
      "About",
      "Ambassadors",
      "The Buzz",
      "The Shop",
      "Download the App",
      "Download for Android",
      "Download for iOS",
      "We’re sorry, but we can’t find the page you were looking for.",
      "Sign In",
      "Join",
      "Join the Hive!",
      "See all →",
      "↓ See more",
      "Oops, something went wrong"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "days"
            ],
            "singular": [
                  "day"
            ]
      },
      "hour": {
            "plural": [
                  "hours"
            ],
            "singular": [
                  "hour"
            ]
      },
      "minute": {
            "plural": [
                  "minutes"
            ],
            "singular": [
                  "minute"
            ]
      },
      "month": {
            "plural": [
                  "months"
            ],
            "singular": [
                  "month"
            ]
      },
      "second": {
            "plural": [
                  "seconds"
            ],
            "singular": [
                  "second"
            ]
      },
      "year": {
            "plural": [
                  "years"
            ],
            "singular": [
                  "year"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
